<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Year</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table :headers="headers" :items="year_list" class="elevation-1" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable = !iseditable">Add New
                    Year</v-btn>
                </template>
                <v-card>
                  <v-form>
                    <v-card-title>
                      <v-row justify="space-between">
                        <span class="headline">{{ formTitle }}</span>
                        <v-btn icon dark @click="dialog = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                      <!-- <span class="headline">{{ formTitle }}</span> -->
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <!-- <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                <v-autocomplete :items="org_list" v-model="editedItem.organization" label="Select Organization" :rules="[v => !!v || 'required']" persistent-hint
                                                        :hint="editedIndex==-1?'For example :: VIT,VIIT':'For example :: VIT,VIIT'"></v-autocomplete>
                                            </v-col>
                                             <v-col cols="12" v-if='editedIndex != -1'>Organization
                                                <v-select dense v-model="editedItem.organization" :items="org_list" item-value="id" :rules="[v => !!v || 'required']" persistent-hint  :error="errorMsg.organization?true:false" :error-messages="errorMsg.organization?'Required':''" 
                                                        :hint="editedIndex!=-1?'For example :: VIT,VIIT':'For example :: VIT,VIIT'"></v-select>
                                            </v-col> -->

                        <v-col cols="12">
                          <v-text-field v-model="editedItem.name" label="Year *" dense persistent-hint
                            :error-messages="error.editedItem.name ? 'required' : ''" :hint="editedIndex == -1
                              ? 'For example :: FY,SY,TY......'
                              : 'For example :: FY,SY,TY......'
                              "></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model="editedItem.display_name" label="Display Name *"
                            :error-messages="error.editedItem.display_name ? 'required' : ''" persistent-hint :hint="editedIndex == -1
                              ? 'For example :: FY,SY,TY......'
                              : 'For example :: FY,SY,TY......'
                              "></v-text-field>
                          <span v-if="editedIndex == -1"></span>
                        </v-col>
                        <v-col>
                          <input type="hidden" v-model="editedItem.id" name="id" />
                          <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                        </v-col>
                        <v-row>
                          <v-col cols="6" sm="3" md="2">
                            <v-checkbox v-model="editedItem.isactive" color="success" label="isActive?"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                      <v-btn color="primary darken-1" dark @click="save(item)" :disabled="valid">Save</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template> -->
          <template v-slot:item.isactive="{ item }">
            <v-switch v-model="item.isactive" @change="activeswitch(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefun(item.id)">
                  mdi-delete</v-icon></v-btn>
            </div>
          </template>
          <!-- <template v-slot:item.action="{ item }">
                <div>
                    <v-avatar style="" size="25" class="edit-avatar">
                        <v-icon class="edit-v-icon mr-2" @click="editItem(item)">
                            edit
                        </v-icon>
                    </v-avatar>
                   

                </div>
            </template> -->
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    valid: false,
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    year_list: null,
    org_list: null,

    errorMsg: {
      id: false,
    },
    search: "",
    headers: [
      {
        text: "Sr. No",

        value: "sr_no",
      },
      {
        text: "Year",
        value: "name",
      },
      {
        text: "Display Name",
        value: "display_name",
      },
      {
        text: "Organization",
        value: "organization",
      },

      {
        text: "Active",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      display_name: "",
      isactive: true,
    },
    error: {
      editedItem: {

        name: "",
        display_name: "",

      },
    },
    defaultItem: {
      id: 0,
      name: "",
      display_name: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Year" : "Edit Year";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    this.onload();
  },

  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    onload() {
      axios
        .post("/AdminMaster/addYear")
        .then((res) => {
          if (res.data.msg == "200") {
            this.year_list = res.data.year_list;
            this.load = true;
          } else {
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        });
    },
    initializeErrorMsgFalse() {
      this.errorMsg.id = false;
    },
    editItem(item) {
      this.iseditable = false;
      this.editedIndex = this.year_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.initializeErrorMsgFalse();
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.name == "" || /^[ \d]+$/.test(this.editedItem.name) || this.hasWhiteSpace(this.editedItem.name)) {
        this.showSnackbar("red", "please Enter Year");
        this.error.editedItem.name = true;
        return;
      } else if (this.editedItem.display_name == "" || /^[ \d]+$/.test(this.editedItem.display_name) || this.hasWhiteSpace(this.editedItem.display_name)) {
        this.showSnackbar("red", "please Enter Display Name");
        this.error.editedItem.display_name = true;
        return;
      } else {
        if (this.editedIndex > -1) {
          //  console.log("edit.." + this.editedItem);
          this.iseditable = false;
          axios
            .post("/AdminMaster/editYear", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.showSnackbar("#4caf50", "Year Updated Successfully..."); // show snackbar on success
                Object.assign(
                  this.year_list[this.editedIndex],
                  this.editedItem
                );
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Year Already Present!!!"); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
              //window.console.log(error);
            });
        } else {
          axios
            .post("/AdminMaster/saveYear", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.year_list.push(this.editedItem);
                this.showSnackbar("#4caf50", "New Year Added Successfully!!!"); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Year Already Present!!!"); // show snackbar on error
              }
              this.onload();
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      }
    },
    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      if (confirm("Do you really want to delete?")) {
        axios
          .post("/AdminMaster/deleteyear", data)
          .then((res) => {
            if (res.data.msg == "200") {
              //  console.log("success");
              this.showSnackbar("#4caf50", "Deleted Successfully...");
              this.onload();
            } else {
              this.showSnackbar("#b71c1c", res.data.msg);
              //error
            }
            this.onload();
          })
          .catch((error) => {
            window.console.log(error);
          });
        this.close();
      }
    },
    activeswitch(item) {
      //  console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activeyear", data)
        .then((res) => {
          //console.log("res.data");
          //console.log(res.data);
          if (res.data.msg == "200") {
            //console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    isValidated() {
      if (this.editedItem.id) {
        return true;
      } else {
        if (!this.editedItem.id) {
          this.errorMsg.id = true;
        }

        return false;
      }
    },
    // .....end of isValidated()
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
